<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实验报告(查看)</div>
      <!-- <el-input
        class="elinput"
        size="small"
        v-model="searchvalue"
        placeholder="请输入内容"
        clearable
      >
        <i class="el-icon-search el-icon_search" slot="suffix"> </i>
      </el-input> -->
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="headcontain">
        <!-- <div class="lefttitle">查看实验报告</div> -->
        <div class="returnbt" @click="returnbt">返回</div>
      </div>
      <div class="titlecontain">
        <div class="text1">水土保持方案编制实验报告</div>
        <div class="text">王宝利&emsp;&emsp;&emsp;0123456789</div>
        <div class="text">文法学院 / 视觉传达设计 / 2001级视觉设计1班</div>
      </div>
      <div class="bodycontain">
        <div class="text">实验报告：</div>
        <div class="item">
          <div class="text">1 前言</div>
          <div class="text">
            &emsp;&emsp;校外生产实习我们小组来到了浙江舟山正大集团的六横岛上的3个大虾养殖场（鸿祥、华祥、章鑫大虾养殖场）进行为期1个月的实习。我们养殖的是南美白对虾，六横岛的大虾厂主要是进行日常养殖，即将青年虾养至成虾的过程，苗种来自于海南和浙江其他虾厂，所以我们没有经历南美白对虾的苗种繁育。南美白对虾日常养殖的工作由拌料、投料、排污、巡塘和品质管理检测，当虾长到一定规格时，会进行分苗，即将塘内的虾分往其他几个规格更大的塘口，给长大的虾提供更大的生存空间，便于其生长。
            校内生产实习我们在老师的指导下学习并实践了泥鳅的人工繁育，并进行了泥鳅、黄颡鱼、团头鲂、草鱼、鳜鱼、异育银鲫6种鱼类人工繁育的虚拟仿真实验。
          </div>
        </div>
        <div class="item">
          <div class="text">2 实习安排</div>
          <div class="text">2.1 实习总体安排</div>
          <div class="text">
            校外实习（浙江舟山六横岛正大集团鸿祥大虾厂）：2021.4.26-2021.5.20
          </div>
          <div class="text">
            校内实习（华中农业大学水产新基地）：2021.6.9-2021.6.20
          </div>
          <div class="text">2.2 校外实习日常工作安排</div>
          <div class="text">投料：一天三餐（早6点、中午10点30分、下午4点）</div>
          <div class="text">
            拌料：中午、下午各拌一餐，一般提前一小时到一小时半拌料
          </div>
        </div>

        <div class="appendix">
          <div class="text">附件:</div>
          <div class="text text1">水土保持方案编制实验报告.pdf</div>
          <el-image class="elimage1" :src="icon1" />
          <el-image class="elimage2" :src="icon2" />
        </div>
        <div class="score">评分：80</div>
        <div class="score">评语：数据需要完善。</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/查看.png"),
      icon2: require("@/assets/下载.png"),
    };
  },
  computed: {},
  methods: {
    returnbt() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        // name: "PersonalcenterMainpage",
        query: {
          id: 2,
          componentId: "Myexperiment",
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">


.mainpage {
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;

      font-weight: 400;
      color: #288add;
      user-select: none;
      white-space: nowrap;
    }
    .elinput {
      width: 300px;
      ::v-deep .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    height: 1008px;
    // background: #f7f7f7;
    background: #ffffff;
    border-radius: 4px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    .headcontain {
      display: flex;
      justify-content: flex-end;
      align-content: flex-end;
      margin-bottom: 20px;
      .lefttitle {
        font-size: 25px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
        user-select: none;
      }
      .returnbt {
        width: 88px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
      }
    }
    .titlecontain {
      height: 200px;
      background: #f7f7f7;
      box-sizing: border-box;
      padding: 30px;
      .text1 {
        font-size: 25px;
        font-weight: 500;
        color: #222222;
      }

      .text {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
      }
    }
    .bodycontain {
      height: 900px;
      background: #f7f7f7;
      box-sizing: border-box;
      padding: 30px;
      border-top: 1px solid #cccccc;
      .text {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
      }
      .appendix {
        margin-top: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          font-size: 20px;
          font-weight: 400;
          color: #666666;
        }
        .text1 {
          margin-left: 5px;
          color: #3d84ff;
        }
        .elimage1 {
          margin-top: 15px;
          margin-left: 15px;
        }
        .elimage2 {
          margin-top: 10px;
          margin-left: 15px;
        }
      }
      .score {
        margin-top: 80px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>
